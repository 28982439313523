export enum FormName {
  Other = 'other',
  Choice = 'choice',
  General = 'general',
  Template = 'template',
  Settings = 'settings',
  Address = 'address',
  Personal = 'personal',
  Sales = 'sales',
  Profile = 'profile',
  Application = 'application',
  Interventions = 'interventions',
  Type = 'type',
  RegionDetails = 'regionDetails',
  Method = 'method',
  School = 'school',
  StatusId = 'status_id',
  StudentIntergrip = 'studentIntergrip',
  Key = 'key',
  Student = 'student',
  Signup = 'signup',
  StudentMbo = 'studentMbo',
  Message = 'message',
}
