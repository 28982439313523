import { Injectable } from '@angular/core';
import { AuthConfig, AuthService as CapturumAuthService } from '@capturum/auth';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PageRoute } from '@core/enums/general/routes.enum';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { ResetUserState, SetUser, StopImpersonateUser } from '@core/state/user/user.actions';
import { tap } from 'rxjs/operators';
import { AuthProvider } from '@capturum/auth/lib/auth.provider';
import { UserModel } from '@features/user/models/user.model';
import { RouterUtils } from '@shared/utils/router/router.utils';
import { UrlParams } from '@core/enums/url-params.enum';
import { LocalParamsService } from '@core/services/local-params.service';
import jwt_decode from 'jwt-decode';
import Intercom from "@intercom/messenger-js-sdk";

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CapturumAuthService implements AuthProvider {
  constructor(
    config: AuthConfig,
    private httpClient: HttpClient,
    private router: Router,
    private store: Store,
    private paramsService: LocalParamsService,
  ) {
    super(config, httpClient);
  }

  public handleSuccessfulLogin(response: any): void {
    try {
      const token = localStorage.getItem('token');
      const jwt = jwt_decode(token) as any;

      Intercom({
          api_base: "https://api-iam.intercom.io",
          app_id: jwt.intercom.app_key,
          user_id: jwt.intercom.user_id,
          name: jwt.name,
          email: jwt.email,
          user_hash: jwt.intercom.hmac,
          user_type: "portal-user",
          module: "Management"
      })
    } catch (Error) {
      console.log(Error);
    }

    this.store.dispatch(new StopImpersonateUser());
    const cleanUrl: string = RouterUtils.revertUrlChanges(this.router.url);
    const routeIndex: number = cleanUrl.indexOf(UrlParams.RedirectUrl);
    const roleKeyIndex: number = cleanUrl.indexOf(`${UrlParams.RedirectRoleKey}=`);

    const route: string = routeIndex > -1 ? cleanUrl.substring(routeIndex, roleKeyIndex > -1 ? roleKeyIndex : undefined)
      .replace(`${UrlParams.RedirectUrl}=`, '')
      .replace('&', '') : '/';

    const roleKey: string = roleKeyIndex > -1 ? cleanUrl.substr(roleKeyIndex)
      .replace(`${UrlParams.RedirectRoleKey}=`, '') : undefined;

    localStorage.setItem('redirectAfterLogin', JSON.stringify({
      roleKey,
      route,
    }));

    if (response.shouldSetup2FA) {
      localStorage.setItem('tempToken', response.token);
      this.router.navigate([PageRoute.twoFactorActivate]);
      return;
    }
    if (response.method) {
      this.router.navigate([PageRoute.twoFactorLogin], {
        queryParams: {
          method: response.method,
          userId: response.user_id,
        },
      });
    } else {
      if (roleKey) {
        this.paramsService.roleKeyUpdated.next({
          roleKey,
          route,
        });
        localStorage.removeItem('redirectAfterLogin');
        this.router.navigate(['/']);
        this.store.dispatch(new SetUser(this.getUser() as UserModel));
        return;
      }

      localStorage.removeItem('tempToken');
      this.router.navigate([route]);
      this.store.dispatch(new SetUser(this.getUser() as UserModel));
    }
  }

  public activateUser(token: string, userId: string, postData: { password: string, password_confirmation: string }): Observable<void> {
    return this.httpClient.post<void>(`${environment.baseUrl}/auth/user/${userId}/activate`, postData, { params: { token } });
  }

  public logout(): Observable<boolean> {
    try  {
      return super.logout().pipe(tap(() => {
        this.store.dispatch(new ResetUserState());
      }));
    } catch (e) {
      return null;
    }
  }
}
