export enum Permissions {
  Show = 'show',
  Manage = 'manage',
  Destroy = 'destroy',
  Update = 'update',
  Delete = 'delete',
  Create = 'create',
  Tenant = 'tenant',
  School = 'school',
  Report = 'report',
  ChoiceStudentYear = 'choice-student-year',
  Student = 'student',
  DaycareStudent = 'daycare-student',
  StudentPending = 'student-pending',
  StudentFetchService = 'student-fetch-service',
  SchoolClass = 'school-class',
  Choiceable = 'choiceable',
  Export = 'export',
  SignupPhase = 'signup-phase',
  Settings = 'settings',
  BaseDataValue = 'base-data-value',
  TodoStudent = 'todo-student',
  RiskStudent = 'risk-student',
  SignupStatusAlias = 'signup-status-alias',
  BestChoiceExport  = 'best-choice-export',
  MboChoiceStudentYear = 'mbo-choice-student-year',
  BestChoiceStudentYear = 'best-choice-student-year',
  MboBestChoiceStudentYear = 'mbo-best-choice-student-year',
  Mbo = 'mbo',
  MboMbo = 'mbo-mbo',
  OverviewStudents = 'overview-students',
  MyStudents = 'my-students',
  Offer = 'offer',
  Students = 'students',
  Dossier = 'dossier',
  Pipeline = 'pipeline',
  MboSettings = 'mbo-settings',
  SharedFile = 'shared-file',
  Info = 'info',
  List = 'list',
  ImportFiles = 'import-files',
  Import = 'import',
  Search = 'search',
  Sort = 'sort',
  Role = 'role',
  Intergrip1Manage = 'intergrip1-manage',
  Phases = 'phases',
  StudentDetails = 'student-details',
  Data = 'data',
  NotAssigned = 'not-assigned',
  MyStudent = 'my-student',
  Active = 'active',
  Int1Students = 'intergrip1-student',
  Int1Reports = 'intergrip1-report',
  Int1Export = 'intergrip1-export',
  Int1Setting = 'intergrip1-setting',
  Int1SubExport = 'sub-export',
  ProgressVmbo = 'progress-vmbo',
  ProgressMbo = 'progress-mbo',
  CheckMboSoftCheck = 'check-mbo-soft-check',
  WarmTransferPerVmbo = 'warm-transfer-per-vmbo',
  WarmTransferPerMbo = 'warm-transfer-per-mbo',
  BBLWorkplace = 'bbl-workplace',
  MboCheckYearOverview = 'mbo-check-year',
  EverAtLprmc = 'ever-at-lprmc',
  WarmTransfer = 'warm-transfer',
  StudentToDiscuss = 'student-to-discuss',
  Dashboard = 'dashboard',
  DossierConnection = 'dossier-connection',
  CurrentSchoolYear = 'current-school-year',
  TransferCoach = 'transfer-coach',
  Restore = 'restore',
  TransferServicePoint = 'intergrip1-transfer-service-point',
  TaskList = 'task-list',
  DataSyncLog = 'data-sync-log',
  SpecialTransfer = 'special-transfer',
  VoStudent = 'vo-student',
  MboStudent = 'mbo-student',
  DiscussionRound = 'discussion-round',
  StudentYear = 'student-year',
  Training = 'training',
  Send = 'send',
  AdminStudent = 'admin-student',
  INTStudent = 'intergrip1-student',
  StatusDecrease = 'status-decrease',
  NotificationSetting = 'notification-setting',
  Transfer_service_point = 'transfer_service_point',
  Region = 'region',
  GlobalMessageSetting = 'global-message-setting',
  Manage_All = 'manage-all',
  Intervention = 'intervention',
  Location = 'location',
  User = 'user',
  Impersonate = 'impersonate',
  SendActivationEmail = 'send-activation-email',
  TwoFa = '2fa',
  SendDisableEmail = 'send-disable-email',
  ModelLog = 'model-log',
  History = 'history',
  MboLocation = 'mbo-location',
  MboCheck = 'mbo-check',
  Processed = 'processed',
  MBOSchoolProgress = 'mbo-school-progress',
  MboWebservicesConnection = 'mbo-webservices-connection',
  ActivityLog = 'activity-log',
  SystemPreferences = 'system-preferences',
  MandrillEmail = 'mandrill-email',
  ChoiceStudentYearByDate = 'choice-student-year-by-date',
  KeyFigures = 'key-figures',
  Budget = 'budget',
  Prognosis = 'prognosis',
  AssignmentReport = 'assignment-report',
  Providers = 'providers',
  Referrer = 'referrer',
  Comparison = 'comparison',
  ChildList = 'child-list',
  Mentor = 'mentor',
  Label = 'label',
  Add = 'add',
  Edit = 'edit',
}
