<div class="sidebar">
  <div class="menu" *ngIf="activeMenuItem$ | async as activeMenuItem">
    <h2>
      {{
        (activeMenuItem.key + ".module_alias" | setting : "") ||
          (activeMenuItem.title | translate)
      }}
    </h2>
    <div class="list" *ngIf="activeMenuItem.children">
      <ng-container *ngFor="let item of activeMenuItem.children">
        <ng-container *ngxPermissionsExcept="item.permissionsExcept">
          <div
            [attr.data-test]="item?.dataTest"
            class="item"
            (click)="menuItemClick($event)"
            [routerLink]="[item.routerUrl]"
            routerLinkActive="active"
            *ngxPermissionsOnly="item.permissions"
          >
            <i [class]="item.icon"></i> {{ item.title | translate }}
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="banner" *ngIf="logo$ | async">
      <div class="logo">
        <a routerLink="/">
          <img [src]="(logo$ | async)?.public_url" />
        </a>
      </div>
    </div>

    <div *ngIf="showInfoBox" class="ddd-redirection-info-block">
      <i [class]="circleIcon"></i>
      <div class="text-block">
        <p>{{'ddd.more-information.title' | translate}}</p>
        <cap-button
          [label]="'ddd.more-information.website.title' | translate"
          type="button"
          styleClass="primary w-100"
          (click)="redirectToDDD()"
        >
        </cap-button>
      </div>
    </div>
  </div>
</div>
