import {
  Component,
  EventEmitter,
  Input,
  Output, QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { CapturumDialogService, FilterMatchMode, FilterType } from '@capturum/ui/api';
import { FilterMetadata } from 'primeng/api';
import { FilterConfig } from '@core/models/filter-config.model';
import { TranslateService } from '@ngx-translate/core';
import { OverlayModalComponent } from '@shared/components/overlay-modal/overlay-modal.component';
import { MobileFilterComponent } from '@shared/components/mobile-filter/mobile-filter.component';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CapturumInputComponent } from '@capturum/ui/input';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

const DATAFIELD = 'data-field';
const HASICON = 'has-icon';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
  providers: [CapturumDialogService],
  encapsulation: ViewEncapsulation.None,
})
export class TableFilterComponent {
  @ViewChildren('input')
  set inputElement(value: QueryList<CapturumInputComponent>) {
    if (value) {
      this.listenForInputChange(value);
    }
  }

  get filters(): FilterConfig[] {
    return this._filters;
  }

  @Input() set filters(value: FilterConfig[]) {
    if (value) {
      this._filters = value;
    }
  }

  @Input()
  public activeFilters: Record<string, FilterMetadata>;

  @Output()
  public onFilter = new EventEmitter<{ field: string, value: any, matchMode: string }>();
  @Output()
  public onSearch = new EventEmitter<string>();
  @Output()
  public onReset = new EventEmitter<void>();

  public onClose = new BehaviorSubject(false);

  public title: string;

  public filterType = FilterType;

  public fontAwesomeIcon = FontAwesomeIcon;

  private _filters: FilterConfig[] = [];

  constructor(private translateService: TranslateService,
              private dialogService: CapturumDialogService) {
  }

  public listenForInputChange(value: QueryList<CapturumInputComponent>): void {
    value.forEach((inputItem) => {
        if (!inputItem.input) {
            return;
        }
      const input = inputItem.input.nativeElement;
      fromEvent(input, 'input').pipe(
        debounceTime(1000),
      ).subscribe((element: any) => {
        let field;
        let inputElement;
        if (element.path) {
          field = element.path.find((item) => item.classList.contains(HASICON)).getAttribute(DATAFIELD);
          inputElement = this._filters.find((item) => item.field === field);
        } else {
          field = element.target.parentElement.parentElement.parentElement.getAttribute(DATAFIELD);
          inputElement = this._filters.find((item) => item.field === field);
        }

        if (inputElement.isSearch) {
          this.onSearch.emit(element.target.value);
        } else {
          this.onFilter.emit({
            field: inputElement.field,
            value: element.target.value,
            matchMode: inputElement.matchMode || 'like',
          });
        }
      });
    });
  }

  public setFilter(value: any, field: string, matchMode: string, isSearch?: boolean): void {
    if (isSearch) {
      if (value?.length >= 3 || value?.length === 0) {
        this.onSearch.emit(value);
      }
    } else {
      this.onFilter.emit({ field, value, matchMode });
    }
  }

  public setInputSwitchFilter(checked: boolean, field: string): void {
    this.setFilter(checked, field, checked ? FilterMatchMode.NOT_EQUALS : FilterMatchMode.EQUALS, false);
  }

  public reset(): void {
    if (this.activeFilters) {
      this.activeFilters = {};
    }
    this.onReset.emit();
  }

  public openLogsSidebar(): void {
    this.dialogService.open(
      MobileFilterComponent,
      {
        data: {
          activeFilter: this.activeFilters,
          filters: this._filters,
          onReset: () => this.onReset.emit(),
          onSearch: (value: string) => this.onSearch.emit(value),
          onFilter: (value: any, field: string, matchMode: string, isSearch?: boolean) => this.setFilter(value, field, matchMode, isSearch),
          onClose: this.onClose,
          title: this.translateService.instant('table.filters'),
        },
      },
      OverlayModalComponent,
    );
  }
}
