import { FieldType } from '@ngx-formly/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-multi-select-formly-type',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./multi-select.formly-type.scss'],
  template: `
    <ng-container *ngIf="to.options | observablePipe | async as options">
      <cap-multi-select
        [hidden]="formControl?.disabled"
        #capMultiSelect
        [options]="options"
        [formlyAttributes]="field"
        [formControl]="formControl"
        [defaultLabel]="(to.translatePlaceholder || to.placeholder) | observablePipe | async"
        [disabled]="to.disabled"
        [styleClass]="to.styleClass"
        [inputId]="field.key"
        [filter]="to.filter || defaultOptions.templateOptions.filter"
        [filterBy]="to.filterBy || defaultOptions.templateOptions.filterBy"
        [filterPlaceHolder]="to.filterPlaceHolder"
        [appendTo]="to.appendTo"
        [scrollHeight]="to.scrollHeight || defaultOptions.templateOptions.scrollHeight"
        [readonly]="to.readOnly"
        [group]="to.group">
        <ng-template #selectedItemsTemplate capTemplate="selectedItems" let-items>
          <div class="d-flex selected-item-chips">
            <div class="selected-items-chip" *ngFor="let item of items | slice : 0 : 2; let last = last">
              {{ item | findLabelByValue : options }}<span *ngIf="!last">,&nbsp;</span>
            </div>

            <div class="d-flex" *ngIf="items && items.length > 2">
              <div class="dots-prefix">...</div>
              <div class="more-results-counter">+{{ items.length - 2 }}</div>
            </div>
          </div>
        </ng-template>
      </cap-multi-select>

      <ng-container *ngIf="formControl.disabled">
        <div class="multi-select-readonly-list">
          <span *ngFor="let value of formControl.value | slice : 0 : to.amountOfItemsToDisplay || 2; let last = last">
            {{ value | findLabelByValue : options }}<span *ngIf="!last">,&nbsp;</span>
          </span>

          <app-more-results-overlay *ngIf="formControl.value && formControl.value.length > to.amountOfItemsToDisplay"
                                    [title]="to.translateLabel"
                                    [items]="formControl.value | valuesToLabels : options">
          </app-more-results-overlay>
        </div>
      </ng-container>
    </ng-container>
  `,
})
export class MultiSelectFormlyTypeComponent extends FieldType {
  public defaultOptions = {
    templateOptions: {
      filter: true,
      filterBy: 'label',
      appendTo: null,
      scrollHeight: '200px',
      overlayVisible: false,
      dataKey: 'value',
      onChange: null,
      amountOfItemsToDisplay: 10,
    },
  };
}
