import { LegacyComponent } from '@features/legacy/pages/legacy/legacy.component';
import { DateParserPipe } from '@shared/pipes/date-parser/date-parser.pipe';
import { IsActivePipe } from '@shared/pipes/is-active/is-active.pipe';
import { TransferTypePipe } from './pipes/transfer-type/transfer-type.pipe';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyFilterModule } from 'primeng/keyfilter';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
// Components
import { InputCodeComponent } from './components/input-code/input-code.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { CapturumInputModule } from '@capturum/ui/input';
import { FormCardComponent } from '@shared/components/form-card/form-card.component';
import { CapturumCardModule } from '@capturum/ui/card';
import { FormlyModule } from '@ngx-formly/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { InScreenSubMenuItemComponent } from './components/in-screen-sub-menu-item/in-screen-sub-menu-item.component';
import { InScreenSubMenuComponent } from './components/in-screen-sub-menu/in-screen-sub-menu.component';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DateTimePipe } from './pipes/date-time/date-time.pipe';
import { TableFilterComponent } from './components/table-filter/table-filter.component';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { BadgeComponent } from './components/badge/badge.component';
import { InputSwitchTypeComponent } from '@shared/formly/types/input-switch/input-switch.formly-type';
import { IntBaseListComponent } from '@shared/base/int-base-list.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { RoleAccordionsComponent } from '@shared/components/role-accordions/role-accordions.component';
import { ImageComponent } from './formly/types/image/image.component';
import { ModelLogModule } from '@capturum/complete';
import { ModelLogTimelineComponent } from './components/model-log-timeline/model-log-timeline.component';
import { DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { UniqueKeysPipe } from '@shared/pipes/unique-keys/unique-keys.pipe';
import { IsAdminPipe } from '@shared/pipes/is-admin/is-admin.pipe';
import { IsAdminVoUserPipe} from '@shared/pipes/is-admin-vo-user/is-admin-vo-user.pipe';
import { ModuleIconPipe } from '@shared/pipes/module-icon/module-icon.pipe';
import { CapturumEditorModule } from '@capturum/ui/editor';
import { EditorFormlyTypeComponent } from '@shared/formly/types/editor/editor.formly-type';
import { HorizontalFormCardComponent } from '@shared/components/horizontal-form-card/horizontal-form-card.component';
import { PickListComponent } from './components/pick-list/pick-list.component';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { PickListTableComponent } from './components/pick-list-table/pick-list-table.component';
import { ArrayToTextPipe } from '@shared/pipes/array-to-text/array-to-text.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { BaseDataTranslationPipe } from './pipes/base-data-translation/base-data-translation.pipe';
import { CapitalizeFirstPipe } from '@shared/pipes/capitalize-first/capitalize-first.pipe';
import { AuthSmsComponent } from '@shared/components/two-factor/auth-sms/auth-sms.component';
import { AuthGoogleComponent } from '@shared/components/two-factor/auth-google/auth-google.component';
import { AuthEmailComponent } from '@shared/components/two-factor/auth-email/auth-email.component';
import { TwoFactorFormComponent } from '@shared/components/two-factor/two-factor-form/two-factor-form.component';
import { AccountDetailItemComponent } from '@shared/components/account-detail-item/account-detail-item.component';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { ModuleImagePipe } from './pipes/module-image/module-image.pipe';
import { MultiSelectFormlyTypeComponent } from '@shared/formly/types/multi-select/multi-select.formly-type';
import { FindLabelByValuePipe } from './pipes/find-label-by-value/find-label-by-value.pipe';
import { MoreResultsOverlayComponent } from './components/more-results-overlay/more-results-overlay.component';
import { TooltipModule } from 'primeng/tooltip';
import { ValuesToLabelsPipe } from './pipes/values-to-labels/values-to-labels.pipe';
import { HtmlFormlyTypeComponent } from '@shared/formly/types/html/html-formly-type';
import { MailTagListComponent } from './componens/mail-tag-list/mail-tag-list.component';
import { ChoicesInstancePipe } from '@shared/pipes/choice-instance/choice-instance.pipe';
import { ChoiceStatusPipe } from '@shared/pipes/choice-status/choice-status.pipe';
import { FilterUserRowActionsPipe } from '@shared/pipes/filter-user-row-actions/filter-user-row-actions.pipe';
import { ShowRegionsPipe } from './pipes/show-regions/show-regions.pipe';
import { ShowTrainingStatusPipe } from './pipes/show-training-status/show-training-status.pipe';
import { FormatMbocheckTypePipe } from './pipes/format-mbo-check-type/format-mbocheck-type.pipe';
import { JsonViewerComponent } from './components/json-viewer/json-viewer.component';
import { JsonViewerContainerComponent } from './components/json-viewer/json-viewer-container.component';
import { TrainingInstancePipe } from './pipes/training-instance/training-instance.pipe';
import { GenerateTransferActionsPipe } from './pipes/generate-transfer-actions/generate-transfer-actions.pipe';
import { TextToAsteriskPipe } from '@shared/pipes/text-to-asterisk/text-to-asterisk.pipe';
import { SchoolProgressBarUnitPipe } from '@shared/pipes/school-progress-bar-unit/school-progress-bar-unit.pipe';
import {
  ProgressBarProgressCountPipe,
} from '@shared/pipes/progress-bar-progress-count/progress-bar-progress-count.pipe';
import { ArrayPathPipe } from '@shared/pipes/array-path/array-path.pipe';
import { CheckAllClassesPipe } from '@shared/pipes/check-all-classes/check-all-classes.pipe';
import { GenerateUserButtonsPipe } from '@shared/pipes/generate-user-buttons/generate-user-buttons.pipe';
import { ShowMunicipalitiesPipe } from '@shared/pipes/show-municipalities/show-municipalities.pipe';
import { TableLabelComponent } from '@shared/components/table-label/table-label.component';
import { MboCheckUpdateDatePipe } from '@shared/pipes/mbo-check-update-date/mbo-check-update-date.pipe';
import { ShowTrainingListPipe } from '@shared/pipes/show-training-list/show-training-list.pipe';
import { FromStringToJsonPipe } from '@shared/pipes/from-string-to-json/from-string-to-json.pipe';
import { GetLabelByValuePipe } from '@shared/pipes/get-label-by-value/get-label-by-value.pipe';
import { SanitizePipe } from '@shared/pipes/sanitize-url/sanitize.pipe';
import { AppFormFieldComponent } from '@shared/formly/form-field/form-field-wrapper.component';
import { CalendarTypeComponent } from '@shared/formly/types/calendar/calendar.component';
import {
  GenerateStudentToDiscussActionsPipe,
} from '@shared/pipes/generate-student-to-discuss-actions/generate-student-to-discuss-actions.pipe';
import { ReplaceStringWithPipe } from '@shared/pipes/replace-string-with/replace-string-with.pipe';
import { MobileFilterComponent } from './components/mobile-filter/mobile-filter.component';
import { OverlayModalComponent } from '@shared/components/overlay-modal/overlay-modal.component';
import { ProfileMenuComponent } from '../features/profile-menu/profile-menu.component';
import { CreboActionsPipe } from '@shared/pipes/crebo-actions/crebo-actions.pipe';
import { RoleTranslationPipe } from '@shared/pipes/role-translation/role-translation.pipe';
import {
  TransferServicepointActionsPipe,
} from '@shared/pipes/transfer-servicepoint-actions/transfer-servicepoint.actions.pipe';
import { RMCUploadedPipe } from '@shared/pipes/rmc-uploaded-files/rmc-uploaded-files.pipe';
import { TodoStudentIconColorPipe } from '@shared/pipes/todo-student-icon-color/todo-student-icon-color.pipe';
import { MenuButtonPermissionPipe } from '@shared/pipes/menu-button-permission/menu-button-permission.pipe';
import { IsInArrayPipe } from '@shared/pipes/is-in-array/is-in-array.pipe';
import { ActivityLogTimelineComponent } from '@shared/components/activity-log-timeline/activity-log-timeline.component';
import { ModelLogDiffPipe } from '@shared/pipes/log-different/model-log-diff.pipe';
import { MboCheckMonitorBadgeColorPipe } from '@shared/pipes/check-monitor-badge-color/check-monitor-badge-color.pipe';
import { CutTextPipe } from '@shared/pipes/cut-text/cut-text.pipe';
import { InputTypeComponent } from './formly/types/input/input.component';
import { FormFieldWrapperBP } from '@shared/formly/form-field-bp/form-field.wrapper';
import { ExportItemComponent } from '@shared/components/export-item/export-item.component';
import { ShowRoleTypesPipe } from '@shared/pipes/show-roletypes/show-roletypes.pipe';
import { ShowModulesPipe } from '@shared/pipes/show-modules/show-modules.pipe';
import { MarkedPipe } from './pipes/marked/marked.pipe';
import { InputRadioComponent } from './formly/types/input-radio/input-radio.component';
import {CapturumRadioModule} from "@capturum/ui/radio";
@NgModule({
  declarations: [
    HtmlFormlyTypeComponent,
    InputCodeComponent,
    InScreenSubMenuItemComponent,
    InScreenSubMenuComponent,
    FormCardComponent,
    MenuButtonComponent,
    DateTimePipe,
    TableFilterComponent,
    BadgeComponent,
    InputSwitchTypeComponent,
    IntBaseListComponent,
    RoleAccordionsComponent,
    RightSidebarComponent,
    ImageComponent,
    ModelLogTimelineComponent,
    UniqueKeysPipe,
    IsAdminPipe,
    IsAdminVoUserPipe,
    ModuleIconPipe,
    EditorFormlyTypeComponent,
    HorizontalFormCardComponent,
    PickListComponent,
    PickListTableComponent,
    ArrayToTextPipe,
    LoaderComponent,
    BaseDataTranslationPipe,
    ModuleImagePipe,
    FilterUserRowActionsPipe,
    CapitalizeFirstPipe,
    AccountDetailItemComponent,
    AuthSmsComponent,
    AuthGoogleComponent,
    AuthEmailComponent,
    TwoFactorFormComponent,
    MoreResultsOverlayComponent,
    MultiSelectFormlyTypeComponent,
    FindLabelByValuePipe,
    ValuesToLabelsPipe,
    MailTagListComponent,
    ChoicesInstancePipe,
    ChoiceStatusPipe,
    ShowRegionsPipe,
    ShowTrainingStatusPipe,
    ShowRoleTypesPipe,
    ShowModulesPipe,
    FormatMbocheckTypePipe,
    JsonViewerComponent,
    JsonViewerContainerComponent,
    TrainingInstancePipe,
    GenerateTransferActionsPipe,
    TransferTypePipe,
    TextToAsteriskPipe,
    IsActivePipe,
    SchoolProgressBarUnitPipe,
    ProgressBarProgressCountPipe,
    ArrayPathPipe,
    DateParserPipe,
    CheckAllClassesPipe,
    GenerateUserButtonsPipe,
    LegacyComponent,
    ShowMunicipalitiesPipe,
    TableLabelComponent,
    MboCheckUpdateDatePipe,
    ShowTrainingListPipe,
    FromStringToJsonPipe,
    GetLabelByValuePipe,
    GenerateStudentToDiscussActionsPipe,
    SanitizePipe,
    AppFormFieldComponent,
    CalendarTypeComponent,
    ReplaceStringWithPipe,
    MobileFilterComponent,
    OverlayModalComponent,
    ProfileMenuComponent,
    CreboActionsPipe,
    RoleTranslationPipe,
    TransferServicepointActionsPipe,
    RMCUploadedPipe,
    TodoStudentIconColorPipe,
    MenuButtonPermissionPipe,
    IsInArrayPipe,
    ActivityLogTimelineComponent,
    ModelLogDiffPipe,
    MboCheckMonitorBadgeColorPipe,
    CutTextPipe,
    InputTypeComponent,
    FormFieldWrapperBP,
    ExportItemComponent,
    MarkedPipe,
    InputRadioComponent,
  ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgxPermissionsModule,
        CapturumInputModule,
        KeyFilterModule,
        SidebarModule,
        DropdownModule,
        InputTextModule,
        CapturumButtonModule,
        TranslateModule,
        CapturumSharedModule,
        CapturumCardModule,
        FormlyModule.forChild(),
        OverlayPanelModule,
        CapturumMultiSelectModule,
        CapturumCalendarModule,
        CapturumDropdownModule,
        CapturumInputSwitchModule,
        AccordionModule,
        ModelLogModule,
        DynamicDialogModule,
        CapturumEditorModule,
        CapturumInfoTableModule,
        CapturumCheckboxModule,
        TooltipModule,
        CapturumRadioModule,
    ],
  exports: [
    InputCodeComponent,
    NgxPermissionsModule,
    TranslateModule,
    InScreenSubMenuItemComponent,
    InScreenSubMenuComponent,
    CapturumSharedModule,
    FormCardComponent,
    MenuButtonComponent,
    DateTimePipe,
    TableFilterComponent,
    BadgeComponent,
    RightSidebarComponent,
    FormlyModule,
    RoleAccordionsComponent,
    ModelLogModule,
    DynamicDialogModule,
    UniqueKeysPipe,
    IsAdminPipe,
    IsAdminVoUserPipe,
    ModuleIconPipe,
    CapturumEditorModule,
    HorizontalFormCardComponent,
    PickListComponent,
    PickListTableComponent,
    ArrayToTextPipe,
    LoaderComponent,
    BaseDataTranslationPipe,
    CapitalizeFirstPipe,
    AccountDetailItemComponent,
    AuthSmsComponent,
    AuthGoogleComponent,
    AuthEmailComponent,
    TwoFactorFormComponent,
    ModuleImagePipe,
    FilterUserRowActionsPipe,
    CapturumMultiSelectModule,
    MultiSelectFormlyTypeComponent,
    MoreResultsOverlayComponent,
    HtmlFormlyTypeComponent,
    MailTagListComponent,
    ChoicesInstancePipe,
    ChoiceStatusPipe,
    ShowRegionsPipe,
    ShowTrainingStatusPipe,
    ShowRoleTypesPipe,
    ShowModulesPipe,
    FormatMbocheckTypePipe,
    JsonViewerComponent,
    TrainingInstancePipe,
    GenerateTransferActionsPipe,
    TransferTypePipe,
    TextToAsteriskPipe,
    IsActivePipe,
    SchoolProgressBarUnitPipe,
    ProgressBarProgressCountPipe,
    ArrayPathPipe,
    DateParserPipe,
    OverlayPanelModule,
    CheckAllClassesPipe,
    GenerateUserButtonsPipe,
    ShowMunicipalitiesPipe,
    TableLabelComponent,
    MboCheckUpdateDatePipe,
    ShowTrainingListPipe,
    FromStringToJsonPipe,
    FindLabelByValuePipe,
    GetLabelByValuePipe,
    GenerateStudentToDiscussActionsPipe,
    SanitizePipe,
    AppFormFieldComponent,
    CalendarTypeComponent,
    ReplaceStringWithPipe,
    OverlayModalComponent,
    MobileFilterComponent,
    ProfileMenuComponent,
    CreboActionsPipe,
    RoleTranslationPipe,
    TransferServicepointActionsPipe,
    RMCUploadedPipe,
    TodoStudentIconColorPipe,
    MenuButtonPermissionPipe,
    IsInArrayPipe,
    ActivityLogTimelineComponent,
    ModelLogDiffPipe,
    MboCheckMonitorBadgeColorPipe,
    CutTextPipe,
    InputTypeComponent,
    FormFieldWrapperBP,
    ExportItemComponent,
    MarkedPipe,
  ],
  providers: [
    DatePipe,
    DynamicDialogConfig,
    TranslatePipe,
  ],
})
export class SharedModule {
}
