export enum FontAwesomeIcon {
    Search = 'fas fa-search',
    GlobalEurope = 'fas fa-globe-europe',
    Comments = 'fas fa-comments',
    BriefCase = 'fas fa-briefcase',
    Browser = 'fas fa-browser',
    Envelope = 'fas fa-envelope',
    FileExcel = 'fas fa-file-excel',
    FileExport = 'fas fa-file-export',
    CalendarAlt = 'fas fa-calendar-alt',
    SignIn = 'fas fa-sign-in',
    MailBox = 'fas fa-mailbox',
    ChartLine = 'fas fa-chart-line',
    Tasks = 'fas fa-tasks',
    FileInvoice = 'fas fa-file-invoice',
    Info = 'fas fa-info',
    Share = 'fas fa-share',
    LockAlt = 'fas fa-lock-alt',
    User = 'fas fa-user',
    Sync = 'fas fa-sync',
    PDF = 'fas fa-file-pdf',
    FolderOpen = 'fas fa-folder-open',
    TachomerterAlt = 'fas fa-tachometer-alt',
    Microphone = 'fas fa-microphone',
    Save = 'fas fa-save',
    Exchange = 'fas fa-exchange',
    FileAlt = 'fas fa-file-alt',
    Bars = 'fas fa-bars',
    Fire = 'fas fa-fire',
    ExchangeAlt = 'fas fa-exchange-alt',
    Box = 'fas fa-box',
    SlidersH = 'fas fa-sliders-h',
    ChartBar = 'fas fa-chart-bar',
    ChartUser = 'fas fa-chart-user',
    ObjectGroup = 'fas fa-object-group',
    Cubes = 'fas fa-cubes',
    List = 'fas fa-list',
    Flask = 'fas fa-flask',
    Language = 'fas fa-language',
    Check = 'fas fa-check',
    EyeSlash = 'fas fa-eye-slash',
    University = 'fas fa-university',
    Clock = 'fas fa-clock',
    MapMarker = 'fas fa-map-marker',
    MapMarkerAlt = 'fas fa-map-marker-alt',
    Video = 'fas fa-video',
    PaperPlane = 'fas fa-paper-plane',
    ReTweet = 'fas fa-retweet',
    HomeAlt = 'fas fa-home-alt',
    Plus = 'fas fa-plus',
    Users = 'fas fa-users',
    Desktop = 'fas fa-desktop',
    CodeBrach = 'fas fa-code-branch',
    Ban = 'fas fa-ban',
    ListOl = 'fas fa-list-ol',
    Eye = 'fas fa-eye',
    CalendarCheck = 'fas fa-calendar-check',
    ExternalLinkSquare = 'fas fa-external-link-square',
    UserTimes = 'fas fa-user-times',
    IdCardAlt = 'fas fa-id-card-alt',
    Download = 'fas fa-download',
    ChartArea = 'fas fa-chart-area',
    Cloud = 'fas fa-cloud',
    SignInAlt = 'fas fa-sign-in-alt',
    LevelUpAlt = 'fas fa-level-up-alt',
    ArrowRight = 'fas fa-arrow-right',
    ArrowDown = 'fas fa-arrow-down',
    ArrowUp = 'fas fa-arrow-up',
    HandShake = 'fas fa-handshake',
    File = 'fas fa-file',
    Random = 'fas fa-random',
    Cogs = 'fas fa-cogs',
    ArrowAltLeft = 'fas fa-long-arrow-alt-left',
    EnvelopeOpenText = 'fas fa-envelope-open-text',
    ArrowAltRight = 'fas fa-long-arrow-alt-right',
    Pencil = 'fas fa-pencil',
    Edit = 'fas fa-edit',
    UserCircle = 'fas fa-user-circle',
    Bell = 'fas fa-bell',
    BackPack = 'fas fa-backpack',
    TrashAlt = 'fas fa-trash-alt',
    Cog = 'fas fa-cog',
    Code = 'fas fa-code',
    Link = 'fas fa-link',
    School = 'fas fa-school',
    Times = 'fas fa-times',
    History = 'fas fa-history',
    Building = 'fas fa-building',
    Trash = 'fas fa-trash',
    Calendar = 'fas fa-calendar',
    PowerOff = 'fas fa-power-off',
    InfoCircle = 'fas fa-info-circle',
    Bullhorn = 'fa fa-bullhorn',
    LightBulb = 'fas fa-lightbulb',
    SpinnerThird = 'fas fa-spinner-third',
    Spinner = 'fas fa-spinner',
    PencilAlt = 'fas fa-pencil-alt',
    ChartPie = 'fas fa-chart-pie',
    Key = 'fas fa-key',
    ICursor = 'fas fa-i-cursor',
    CheckSquare = 'fas fa-check-square',
    CheckDouble = 'fas fa-check-double',
    Bolt = 'fas fa-bolt',
    Child = 'fas fa-child',
    HourGlass = 'fas fa-hourglass',
    PuzzlePiece = 'fas fa-puzzle-piece',
    Calculator = 'fas fa-calculator',
    SignOutAlt = 'fas fa-sign-out-alt',
    AlignJustify = 'fas fa-align-justify',
    GraduationCap = 'fas fa-graduation-cap',
    CloudUpload = 'fas fa-cloud-upload',
    CloudUploadAlt = 'fas fa-cloud-upload-alt',
    Print = 'fas fa-print',
    Reply = 'fas fa-reply',
    Question = 'fas fa-question',
    Upload = 'fas fa-upload',
    FileSignature = 'fas fa-file-signature',
    ReplyAll = 'fas fa-reply-all',
    MapSigns = 'fas fa-map-signs',
    Tag = 'fas fa-tag',
    Tags = 'fas fa-tags',
    ClickBoardCheck = 'fas fa-clipboard-check',
    WindowsClose = 'fas fa-window-close',
    Archive = 'fas fa-archive',
    Globe = 'fas fa-globe',
    TheaterMask = 'fas fa-theater-masks',
    ExclamationTriangle = 'fas fa-exclamation-triangle',
    ExclamationCircle = 'fas fa-exclamation-circle',
    CheckCircle = 'fas fa-check-circle',
    Sms = 'fas fa-sms',
    Clone = 'fas fa-clone',
    ClipBoardList = 'fas fa-clipboard-list',
    ThumbsUp = 'fas fa-thumbs-up',
    FlagCheckered = 'fas fa-flag-checkered',
    ShareAlt = 'fas fa-share-alt',
    Dossier = 'fas fa-dossier',
    Comment = 'fas fa-comment',
    Folder = 'fas fa-folder',
    FileImport = 'fas fa-file-import',
    Shapes = 'fas fa-shapes',
    UserTie = 'fas fa-user-tie',
    SyncAlt = 'fas fa-sync-alt',
    UserGraduate = 'fas fa-user-graduate',
    Database = 'fas fa-database',
    Terminal = 'fas fa-terminal',
    Wrench = 'fas fa-wrench',
    Server = 'fas fa-server',
    HandPointRight = 'fas fa-hand-point-right',
    ChalkBoardTeacher = 'fas fa-chalkboard-teacher',
    ChevronCircle = 'fas fa-chevron-circle-right',
    LifeRing = 'fas fa-life-ring',
    EllipsisV = 'fas fa-ellipsis-v',
    Tools = 'fas fa-tools',
    PlayCircle = 'fas fa-play-circle',
    SyncSpin = 'fas fa-sync fa-spin',
    Circle = 'fas fa-circle',
    AngleLeft = 'fas fa-angle-left',
    TelegramPlane = 'fab fa-telegram-plane',
    Location = 'fas fa-location',
    UserLock = 'fas fa-user-lock',
    LevelDown = 'fas fa-level-down',
    Repeat = 'fas fa-repeat',
    RotateLeft = 'fa-regular fa-rotate-left',
    Star = 'fas fa-star',
    MapPin = 'fas fa-map-pin',
    InboxOut = 'fas fa-inbox-out',
    Book = 'fas fa-book',
    QuestionCircle = 'fas fa-question-circle',
    PaperClip = 'fas fa-paperclip',
    ArrowCircleDown = 'fas fa-arrow-circle-down',
    Award = 'fas fa-award',
    Warning = 'fas fa-warning',
    Font = 'fas fa-font',
    Minus = 'fas fa-minus',
    Undo = 'fas fa-undo',
    CaretUp = 'fas fa-caret-up',
    ChevronLeft = 'fas fa-chevron-left',
    ChevronRight = 'fas fa-chevron-right',
    ArrowLeft = 'fas fa-arrow-left',
    ChevronDoubleRight = 'fas fa-chevron-double-right',
    MinusCircle = 'fas fa-minus-circle',
    AngleDoubleRight = 'fas fa-angle-double-right',
    Lock = 'fas fa-lock',
    AngleRight = 'fas fa-angle-right',
    ChevronCircleRight = 'fas fa-chevron-circle-right',
    Family = 'fas fa-family',
    Coins = 'fas fa-coins',
    Receipt = 'fas fa-receipt',
    Wallet = 'fas fa-wallet',
    Route = 'fas fa-route',
    Euro = 'fas fa-euro-sign',
    UserFriends = 'fas user-friends',
    Compass = 'fas fa-compass',
    ScaleUnbalanced = 'fas fa-balance-scale',
    Landmark = 'fas fa-landmark',
    Industry = 'fas fa-industry',
    TimesCircle = 'fas fa-times-circle',
    RulerCombined = 'fas fa-ruler-combined',
    OpenBook = 'fas fa-book-open',
    StreetView = 'fas fa-street-view',
    Divide = 'fas fa-divide',
    IdCardClip = 'fas fa-id-card',
    CommentDots = 'fas fa-comment-dots',
}
