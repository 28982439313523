import { Injectable } from '@angular/core';
import { UriUtils } from '@shared/utils/uri/uri.utils';
import { Entities } from '@core/components/entities.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavMenuItem } from '@core/models/nav-menu-item.model';
import { TranslateService } from '@ngx-translate/core';
import { ModuleIconMap, Modules } from '@core/enums/modules.enum';
import { ManagementPermissions } from '@features/management/management-permissions.enum';
import { AssigmentPermissions } from '@features/assignment/assigment-permissions.enum';
import { LobAssignmentPermissions } from '@features/lob-assignment/lob-assignment-permissions.enum';
import { CarrierAssignmentPermissions } from '@features/carrier-assignment/carrier-assignment-permissions.enum';
import { HandedInAssignemntsPermissions } from '@features/handed-in-assignments/handed-in-assignemnts-permissions.enum';
import { LobActivityActivitiesPerimissions } from '@features/lob-activity-activities/lob-activity-activities-perimissions.enum';
import { ImportPermissions } from '@features/import/import-permissions.enum';
import { LobActivityRegistrationPerimissions } from '@features/lob-activity-registrations/lob-activity-registration-perimissions.enum';
import { LobActivitySettingsPermissions } from '@features/lob-activity-settings/lob-activity-settings-permissions.enum';
import { MboDossierPermissions } from '@features/mbo-dossier/mbo-dossier-permissions.enum';
import { VoDossierPermissions } from '@features/vo-dossier/vo-dossier-permissions.enum';
import { SpecialTransfersPermissions } from '@features/special-transfers/special-transfers-permissions.enum';
import { TrainingsPermissions } from '@features/trainings/trainings-permissions.enum';
import { VoReportPermissions } from '@features/vo-report/vo-report-permissions.enum';
import { SetupPermissions } from '@features/setup/setup-permissions.enum';
import { VoOppPermissions } from '@features/vo-opp/vo-opp-permissions.enum';
import { MboInstitutionPermissions } from '@features/mbo-institution/mbo-institution-permissions.enum';
import { FormSettingsPermissions } from '@features/form-setting/form-settings-permissions.enum';
import { TransferPermissions } from '@features/transfers/transfer-permissions.enum';
import { ToolsPermissions } from '@features/tools/tools-permissions.enum';
import { ConnectionPermissions } from '@features/connection/connection-permissions.enum';
import { UserPermissions } from '@features/user/user-permissions.enum';
import { RegionsPermissions } from '@features/regions/regions-permissions.enum';
import { InstancesPermissions } from '@features/instances/instances-permissions.enum';
import { CommunicationPerimssions } from '@features/communication/communication-perimssions.enum';
import { MboCheckPermissions } from '@features/mbo-check/mbo-check-permissions.enum';
import { Integrip1Permissions } from '@features/intergrip1-manage/integrip1-permissions.enum';
import { MonitorusReportsPermissions } from '@features/monitorus-reports/monitorus-reports-permissions.enum';
import { PhasesPermissions } from '@features/phases/phases-permissions.enum';
import { SignStatusPermissions } from '@features/sign-status/sign-status-permissions.enum';
import arrayPath from '@shared/utils/array-path/array-path.utils';
import { OtherSettingsPermissions } from '@features/other-settings/other-settings-permissions.enum';
import { Permissions } from '@core/enums/permissions.enum';
import { TodoStudentPermissions } from '@features/todo-student/todo-student-permissions.enum';
import { EldPermissions } from '@features/eld/eld-permissions.enum';
import { Intergrip1ConnectionsPermissions } from '@features/intergrip1-connections/intergrip1-connections-permissions.enum';
import { FixesPermissions } from '@features/fixes/fixes-permissions.enum';
import { TransferReportPermissions } from '@features/transfer-reports/transfer-report-permissions.enum';
import { TransferMonitoringPermissions } from '@features/transfer-monitoring/transfer-monitoring.permissions.enum';
import { Int1AdminPermissions } from '@features/int1-admin/int1-admin-permissions.enum';
import { ActiesPermissions } from '@features/acties/acties-permissions.enum';
import { ChoiceSemesterPermissions } from '@features/choice-semester/choice-semester-permissions.enum';
import { InternshipAssignmentsPermissions } from '@features/internship-assignments/internship-assignments-permissions.enum';
import { PronexusPermissions } from '@features/connections-management/pronexus-permissions.enum';
import { AppService } from '@src/app/app.service';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { SalesRequestsPermissions } from '@features/sales-requests/sales-requests-permissions.enum';
import { VoUsersPermissions } from '@features/vo-users/vo-users-permissions.enum';
import { KovPoPermissions } from '@features/kov-po/kov-po-permissions';
import { NgxPermissionsService } from 'ngx-permissions';
import {DashboardOCWPermissions} from "@features/dashboard-ocw/dashboard-ocw-permissions.enum";

@Injectable({
  providedIn: 'root',
})
export class MenuItemService {
  public menuItems: NavMenuItem[] = [
    {
      title: 'intergrip.menu.transfer',
      activeSubRoute: '/transfer-vo',
      routerUrl: UriUtils.list(Entities.Phases),
      exact: false,
      key: 'transfer-vo',
      icon: FontAwesomeIcon.Sync,
      permissions: [],
      children: [
        {
          title: 'intergrip.menu.phases',
          routerUrl: UriUtils.list(Entities.Phases, Modules.TransferVo),
          icon: FontAwesomeIcon.Sync,
          permissions: [arrayPath([Modules.TransferVo, Permissions.SignupPhase, Permissions.Show])],
          dataTest: 'menu-item-phases',
        },
        {
          title: 'transfer-vo.sub-menu.special-transfers.overview.title',
          routerUrl: UriUtils.list(Entities.MboSpecialTransfers, Modules.TransferVo),
          icon: FontAwesomeIcon.List,
          permissions: [
            arrayPath([Modules.SpecialTransfer, Permissions.AdminStudent, Permissions.Show]),
          ],
          dataTest: 'menu-item-discussion-round',
        },
        {
          title: 'intergrip.menu.signup-status',
          routerUrl: UriUtils.list(Entities.SignupStatus, Modules.TransferVo),
          icon: FontAwesomeIcon.SlidersH,
          permissions: [
            arrayPath([Modules.TransferVo, SignStatusPermissions.SignupStatus, Permissions.Show]),
            arrayPath([Modules.TransferVo, SignStatusPermissions.SignupStatus, Permissions.Manage]),
          ],
          dataTest: 'menu-item-status',
        },
        {
          title: 'discussion-round.menu.discussion-round',
          routerUrl: UriUtils.submenu(Entities.DiscussionRound, Modules.TransferVo),
          icon: FontAwesomeIcon.Comments,
          permissions: [
            arrayPath([Modules.DiscussionRound, Permissions.DiscussionRound, Permissions.Show]),
            arrayPath([Modules.DiscussionRound, Permissions.DiscussionRound, Permissions.Manage]),
            arrayPath([Modules.DiscussionRound, `${Permissions.DiscussionRound}-${Permissions.StudentYear}`, Permissions.Create]),
            arrayPath([Modules.DiscussionRound, `${Permissions.DiscussionRound}-${Permissions.StudentYear}`, Permissions.Update]),
            arrayPath([Modules.DiscussionRound, `${Permissions.DiscussionRound}-${Permissions.StudentYear}`, Permissions.Delete]),
          ],
          dataTest: 'menu-item-discussion-round',
        },
        {
          title: 'intergrip.menu.todo-student',
          routerUrl: UriUtils.submenu(Entities.TodoStudents, Modules.TransferVo),
          icon: FontAwesomeIcon.Users,
          permissions: [
            arrayPath([Modules.TransferVo, Permissions.TodoStudent, Permissions.Show]),
            arrayPath([Modules.TransferMbo, Permissions.TodoStudent, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.Student, Permissions.Show]),
            arrayPath([Modules.TransferMbo, Permissions.Student, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.StudentToDiscuss, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.Int1Students, Permissions.Show]),
            arrayPath([Modules.TransferVo, TodoStudentPermissions.BBL, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.WarmTransfer, Permissions.Show]),
            arrayPath([Modules.TransferVo, TodoStudentPermissions.Int1PotentialDropouts, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.EverAtLprmc, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.BBLWorkplace, Permissions.Show]),
            arrayPath([Modules.TransferVo, TodoStudentPermissions.RiskStatusesAtVo, Permissions.Show]),
            arrayPath([Modules.TransferVo, TodoStudentPermissions.SoftMatch, Permissions.Show]),
            arrayPath([Modules.TransferMbo, TodoStudentPermissions.SoftMatch, Permissions.Show]),
            arrayPath([Modules.TransferVo, TodoStudentPermissions.SoftMatch, Permissions.Manage]),
            arrayPath([Modules.TransferMbo, TodoStudentPermissions.SoftMatch, Permissions.Manage]),
          ],
          dataTest: 'menu-item-todo-student',
        },
        {
          title: 'intergrip.menu.transfer-reports',
          routerUrl: UriUtils.submenu(Entities.reports, Modules.TransferVo),
          icon: FontAwesomeIcon.ChartLine,
          permissions: [
            arrayPath([Modules.TransferVo, Permissions.ChoiceStudentYear, Permissions.Export]),
            arrayPath([Modules.TransferVo, Permissions.ChoiceStudentYear, Permissions.BestChoiceExport]),
            arrayPath([Modules.TransferVo, Permissions.MboChoiceStudentYear, Permissions.Export]),
            arrayPath([Modules.TransferVo, Permissions.Int1Reports, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.MboCheckYearOverview, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.EverAtLprmc, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.Int1SubExport, Permissions.Show]),
            arrayPath([Modules.TransferVo, TransferReportPermissions.ReportsProgressVmbo, Permissions.Show]),
            arrayPath([Modules.TransferVo, TransferReportPermissions.OutflowHistory, Permissions.Show]),
            arrayPath([Modules.TransferVo, TransferReportPermissions.ProcessedMboChecks, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.ProgressVmbo, Permissions.Show]),
          ],
          dataTest: 'menu-item-transfer-reports',
        },
        {
          title: 'intergrip.menu.eld',
          routerUrl: UriUtils.submenu(Entities.eld, Modules.TransferVo),
          icon: FontAwesomeIcon.Comments,
          permissions: [
            arrayPath([Modules.TransferVo, EldPermissions.ImportLog, Permissions.Show]),
          ],
          dataTest: 'menu-item-eld',
        },
        {
          title: 'intergrip.menu.connections',
          routerUrl: UriUtils.submenu(Entities.int1connections, Modules.TransferVo),
          icon: FontAwesomeIcon.CodeBrach,
          permissions: [
            arrayPath([Modules.TransferVo, Intergrip1ConnectionsPermissions.ProNexus, Permissions.Show]),
          ],
          dataTest: 'menu-item-connections',
        },
        {
          title: 'intergrip.menu.fixes',
          routerUrl: UriUtils.submenu(Entities.fixes, Modules.TransferVo),
          icon: FontAwesomeIcon.Cogs,
          permissions: [
            arrayPath([Modules.TransferVo, FixesPermissions.ChoiceDeduplicator, Permissions.Show]),
            arrayPath([Modules.TransferVo, FixesPermissions.ChoiceDeleter, Permissions.Show]),
          ],
          dataTest: 'menu-item-connections',
        },
        {
          title: 'intergrip.menu.exports',
          routerUrl: UriUtils.submenu(Entities.int1export, Modules.TransferVo),
          icon: FontAwesomeIcon.FileExport,
          permissions: [
            arrayPath([Modules.TransferVo, Permissions.Int1Export, Permissions.Show]),
          ],
          dataTest: 'menu-item-exports',
        },
        {
          title: 'intergrip.menu.int1-settings',
          routerUrl: UriUtils.submenu(Entities.int1Settings, Modules.TransferVo),
          icon: FontAwesomeIcon.Cog,
          permissions: [
            arrayPath([Modules.TransferVo, Permissions.Int1Setting, Permissions.Show]),
          ],
          dataTest: 'menu-item-int1-settings',
        },
        {
          title: 'intergrip.menu.monitoring',
          routerUrl: UriUtils.submenu(Entities.intergrip1Monitoring, Modules.TransferVo),
          icon: FontAwesomeIcon.SyncAlt,
          permissions: [
            arrayPath([Modules.TransferVo, TransferMonitoringPermissions.MonitoringProgressVmbo, Permissions.Show]),
            arrayPath([Modules.TransferVo, TransferMonitoringPermissions.MonitoringProgressMbo, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.CheckMboSoftCheck, Permissions.Show]),
            arrayPath([Modules.TransferVo, TransferMonitoringPermissions.MonitoringMboCheckYearOverview, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.WarmTransferPerVmbo, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.WarmTransferPerMbo, Permissions.Show]),
            arrayPath([Modules.TransferVo, Permissions.BBLWorkplace, Permissions.Show]),
            arrayPath([Modules.TransferVo, TransferMonitoringPermissions.DashBoard, Permissions.Show]),
            arrayPath([Modules.TransferVo, TransferMonitoringPermissions.MonitoringInt1SubExport, Permissions.Show]),
          ],
          dataTest: 'menu-item-monitoring',
        },
        {
          title: 'intergrip.menu.int1-admin',
          routerUrl: UriUtils.submenu(Entities.admin, Modules.TransferVo),
          icon: FontAwesomeIcon.Sync,
          permissions: [
            arrayPath([Modules.TransferVo, Int1AdminPermissions.Int1Phases, Permissions.Show]),
            arrayPath([Modules.TransferVo, Int1AdminPermissions.DiscussionRound, Permissions.Show]),
          ],
          dataTest: 'menu-item-int1-admin',
        },
        {
          title: 'intergrip.menu.acties',
          routerUrl: UriUtils.submenu(Entities.acties, Modules.TransferVo),
          icon: FontAwesomeIcon.CheckCircle,
          permissions: [
            arrayPath([Modules.TransferVo, ActiesPermissions.ActionsCheckMboSoftCheck, Permissions.Show]),
            arrayPath([Modules.TransferVo, ActiesPermissions.AssignDiplomas, Permissions.Show]),
            arrayPath([Modules.TransferVo, ActiesPermissions.PrintDiplomaCard, Permissions.Show]),
            arrayPath([Modules.TransferVo, ActiesPermissions.SendChoiceForm, Permissions.Show]),
          ],
          dataTest: 'menu-item-acties',
        },
        {
          title: 'transfer.service-point.overview.title',
          routerUrl: UriUtils.list(Entities.ServicePoint, Modules.TransferVo),
          icon: FontAwesomeIcon.InfoCircle,
          permissions: [
            arrayPath([Modules.TransferVo, Permissions.Transfer_service_point, Permissions.Show]),
            arrayPath([Modules.TransferMbo, Permissions.Transfer_service_point, Permissions.Show]),
            arrayPath([Modules.TransferMbo, Permissions.Transfer_service_point, Permissions.Manage]),
            arrayPath([Modules.TransferMbo, Permissions.Transfer_service_point, Permissions.Manage_All]),
            arrayPath([Modules.TransferVo, Permissions.Transfer_service_point, Permissions.Manage_All]),
            arrayPath([Modules.TransferVo, Permissions.Transfer_service_point, Permissions.Manage]),
          ],
          dataTest: 'menu-item-transfer-service-point',
        },
      ],
    },
    {
      title: 'intergrip.menu.transfer-mbo',
      activeSubRoute: '/transfer-mbo',
      routerUrl: UriUtils.list(Entities.Phases),
      exact: false,
      key: 'transfer-mbo',
      icon: FontAwesomeIcon.Sync,
      permissions: [],
      children: [
        {
          title: 'intergrip.menu.todo-student',
          routerUrl: UriUtils.submenu(Entities.TodoStudents, Modules.TransferMbo),
          icon: FontAwesomeIcon.Users,
          permissions: [
            arrayPath([Modules.TransferMbo, Permissions.Mbo, Permissions.TodoStudent, Permissions.Show]),
            arrayPath([Modules.TransferMbo, Permissions.Mbo, Permissions.Student, Permissions.Show]),
          ],
          dataTest: 'menu-item-todo-student',
        },
        {
          title: 'intergrip.menu.transfer-reports',
          routerUrl: UriUtils.submenu(Entities.reports, Modules.TransferMbo),
          icon: FontAwesomeIcon.ChartLine,
          permissions: [
            arrayPath([Modules.TransferMbo, Permissions.MboMbo, Permissions.ChoiceStudentYear, Permissions.Export]),
            arrayPath([Modules.TransferMbo, Permissions.MboMbo, Permissions.BestChoiceStudentYear, Permissions.Export]),
          ],
          dataTest: 'menu-item-transfer-reports',
        },
        {
          title: 'transfer.service-point.overview.title',
          routerUrl: UriUtils.list(Entities.ServicePoint, Modules.TransferMbo),
          icon: FontAwesomeIcon.InfoCircle,
          permissions: [
            arrayPath([
              Modules.TransferVo,
              Permissions.Transfer_service_point,
              Permissions.Show,
            ]),
            arrayPath([
              Modules.TransferMbo,
              Permissions.Transfer_service_point,
              Permissions.Show,
            ]),
            arrayPath([
              Modules.TransferMbo,
              Permissions.Transfer_service_point,
              Permissions.Manage,
            ]),
            arrayPath([
              Modules.TransferMbo,
              Permissions.Transfer_service_point,
              Permissions.Manage_All,
            ]),
            arrayPath([
              Modules.TransferVo,
              Permissions.Transfer_service_point,
              Permissions.Manage_All,
            ]),
            arrayPath([
              Modules.TransferVo,
              Permissions.Transfer_service_point,
              Permissions.Manage,
            ]),
          ],
          dataTest: 'menu-item-transfer-service-point',
        },
      ],
    },
    {
      title: 'intergrip.menu.lobdossier',
      activeSubRoute: '/lobdossier',
      routerUrl: UriUtils.list(Entities.lobDossier),
      exact: false,
      key: Entities.lobDossier,
      icon: FontAwesomeIcon.Dossier,
      permissions: [],
      children: [
        {
          title: 'lobdossier.menu.management.title',
          routerUrl: UriUtils.submenu(Entities.lobManagement, Modules.Lobdossier),
          icon: FontAwesomeIcon.Wrench,
          permissions: [arrayPath([Modules.Lobdossier, ManagementPermissions.Management, Permissions.Show])],
        },
        {
          title: 'lobdossier.menu.assignment.title',
          routerUrl: UriUtils.submenu(Entities.assignment, Modules.Lobdossier),
          icon: FontAwesomeIcon.Folder,
          permissions: [arrayPath([Modules.Lobdossier, AssigmentPermissions.Assigment, Permissions.Show])],
        },
        {
          title: 'lobdossier.menu.my-students.title',
          routerUrl: UriUtils.submenu(Entities.lobdossierMyStudents, Modules.Lobdossier),
          icon: FontAwesomeIcon.Users,
          permissions: [arrayPath([Modules.Lobdossier, Permissions.MyStudents, Permissions.Show])],
        },
        {
          title: 'lobdossier.menu.lob-assignment.title',
          routerUrl: UriUtils.submenu(Entities.lobAssignment, Modules.Lobdossier),
          icon: FontAwesomeIcon.ObjectGroup,
          permissions: [
            arrayPath([Modules.Lobdossier, LobAssignmentPermissions.AssignmentAll, Permissions.Show]),
            arrayPath([Modules.Lobdossier, LobAssignmentPermissions.AssignmentReceived, Permissions.Show]),
            arrayPath([Modules.Lobdossier, LobAssignmentPermissions.AssignmentShared, Permissions.Show]),
            arrayPath([Modules.Lobdossier, LobAssignmentPermissions.AssignmentOverview, Permissions.Show]),
            arrayPath([Modules.Lobdossier, LobAssignmentPermissions.AssignmentArchived, Permissions.Show])
          ],
        },
        {
          title: 'internship-assignments.menu.internship-assignments.title',
          routerUrl: UriUtils.submenu(Entities.InternshipAssignments, Modules.Lobdossier),
          icon: FontAwesomeIcon.BriefCase,
          permissions: [
           arrayPath([Modules.InternshipAssignments, InternshipAssignmentsPermissions.InternshipAssignments, Permissions.Show]),
          ],
        },
        {
          title: 'lobdossier.menu.handed-in-assignments.title',
          routerUrl: UriUtils.submenu(Entities.handedInAssignments, Modules.Lobdossier),
          icon: FontAwesomeIcon.CheckSquare,
          permissions: [
            arrayPath([Modules.Lobdossier, HandedInAssignemntsPermissions.VoHandedInAssignments, Permissions.Show]),
            arrayPath([Modules.Lobdossier, HandedInAssignemntsPermissions.MboHandedInAssignments, Permissions.Show]),
          ],
        },
        {
          title: 'lobdossier.menu.report.title',
          routerUrl: UriUtils.submenu(Entities.lobdossierReports, Modules.Lobdossier),
          icon: FontAwesomeIcon.ChartBar,
          permissions: [
            arrayPath([Modules.Lobdossier, Permissions.Report, Permissions.Show])
          ],
        },
        {
          title: 'lobdossier.menu-item.reports.title',
          routerUrl: UriUtils.submenu(Entities.Report, Modules.Lobdossier),
          icon: FontAwesomeIcon.Tasks,
          permissions: [
            arrayPath([Modules.Lobdossier, Permissions.AssignmentReport, Permissions.Show])
          ],
        },
        {
          title: 'lobdossier.menu.carrier-assignment.title',
          routerUrl: UriUtils.submenu(Entities.carrierAssignment, Modules.Lobdossier),
          icon: FontAwesomeIcon.Comments,
          permissions: [
              arrayPath([Modules.Lobdossier, CarrierAssignmentPermissions.CarrierAssigment, Permissions.Show]),
              arrayPath([Modules.Lobdossier, CarrierAssignmentPermissions.MboCarrierAssigment, Permissions.Show])],
        },
        {
          title: 'lobdossier.menu.choice-semester.title',
          routerUrl: UriUtils.submenu(Entities.ChoiceSemester, Modules.Lobdossier),
          icon: FontAwesomeIcon.Flask,
          permissions: [
              arrayPath([Modules.Lobdossier, ChoiceSemesterPermissions.ChoiceSemester, Permissions.Show]),
          ],
        },
        {
          title: 'lobdossier.menu.task-list.title',
          routerUrl: UriUtils.submenu(Entities.TaskList, Modules.Lobdossier),
          icon: FontAwesomeIcon.Tasks,
          permissions: [
              arrayPath([Modules.Lobdossier, Permissions.TaskList, Permissions.Show])
          ],
        },
      ],
    },
    {
      title: 'intergrip.menu.lobactivities',
      activeSubRoute: '/lobactivities',
      routerUrl: UriUtils.list(Entities.lobActivities),
      exact: false,
      key: Entities.lobActivities,
      icon: FontAwesomeIcon.Dossier,
      permissions: [],
      children: [
        {
          title: 'lobactivities.menu.offer.title',
          routerUrl: UriUtils.submenu(Entities.offerReact, Modules.LobActivities),
          icon: FontAwesomeIcon.Server,
          permissions: [arrayPath([Modules.LobActivities, Permissions.Offer, Permissions.Show])],
        },
        {
          title: 'lobactivities.menu.activities.title',
          routerUrl: UriUtils.submenu(Entities.activityOverview, Modules.LobActivities),
          icon: FontAwesomeIcon.ICursor,
          permissions: [
            arrayPath([Modules.LobActivities, LobActivityActivitiesPerimissions.Activities, Permissions.Show])
          ],
        },
        {
          title: 'lobactivities.menu.import.title',
          routerUrl: UriUtils.submenu(Entities.import, Modules.LobActivities),
          icon: FontAwesomeIcon.Download,
          permissions: [arrayPath([Modules.LobActivities, ImportPermissions.Import, Permissions.Show])],
        },
        {
          title: 'lobactivities.menu.my-students.title',
          routerUrl: UriUtils.submenu(Entities.myStudentTab, Modules.LobActivities),
          icon: FontAwesomeIcon.UserTimes,
          permissions: [arrayPath([Modules.LobActivities, Permissions.MyStudents, Permissions.Show])],
        },
        {
          title: 'lobactivities.menu.registrations.title',
          routerUrl: UriUtils.submenu(Entities.registrations, Modules.LobActivities),
          icon: FontAwesomeIcon.SignInAlt,
          permissions: [
            arrayPath([Modules.LobActivities, LobActivityRegistrationPerimissions.Registrations, Permissions.Show]),
            arrayPath([Modules.LobActivities, LobActivityRegistrationPerimissions.MboRegistrations, Permissions.Show]),
            arrayPath([Modules.LobActivities, LobActivityRegistrationPerimissions.VoRegistrations, Permissions.Show]),
          ],
        },
        {
          title: 'lobactivities.menu.settings.title',
          routerUrl: UriUtils.submenu(Entities.Settings, Modules.LobActivities),
          icon: FontAwesomeIcon.SlidersH,
          permissions: [
            arrayPath([Modules.LobActivities, Permissions.Settings, Permissions.Show]),
            arrayPath([Modules.LobActivities, LobActivitySettingsPermissions.AdminSettings, Permissions.Show]),
            arrayPath([Modules.LobActivities, LobActivitySettingsPermissions.VoSettings, Permissions.Show])
          ],
        },
        {
          title: 'lobactivities.menu.students.title',
          routerUrl: UriUtils.submenu(Entities.student, Modules.LobActivities),
          icon: FontAwesomeIcon.Users,
          permissions: [
            arrayPath([Modules.LobActivities, Permissions.Student, Permissions.Show])
          ],
        },
        {
          title: 'lobactivities.menu.all-students.title',
          routerUrl: UriUtils.submenu(Entities.intergrip_students, Modules.LobActivities),
          icon: FontAwesomeIcon.Users,
          permissions: [
            arrayPath([Modules.LobActivities, Permissions.Students, Permissions.Show])
          ],
        },
      ],
    },
    {
      title: 'intergrip.menu.ddd',
      activeSubRoute: '/ddd',
      routerUrl: UriUtils.list(Entities.training),
      exact: false,
      key: 'ddd',
      icon: FontAwesomeIcon.Dossier,
      permissions: [],
      children: [
        {
          title: 'ddd.menu.dashboard.title',
          routerUrl: UriUtils.submenu(Entities.Dashboard, Modules.Ddd),
          icon: FontAwesomeIcon.TachomerterAlt,
          permissions: [
            arrayPath([Modules.Ddd, Permissions.Dashboard, Permissions.Show]),
          ],
        },
        {
          title: 'ddd.menu.dossier-connection.title',
          routerUrl: UriUtils.submenu(Entities.DossierConnection, Modules.Ddd),
          icon: FontAwesomeIcon.Link,
          permissions: [
            arrayPath([Modules.Ddd, Permissions.DossierConnection, Permissions.Show]),
          ],
        },
        {
          title: 'ddd.menu.dossiers.title',
          routerUrl: UriUtils.submenu(Entities.dossiers, Modules.Ddd),
          icon: FontAwesomeIcon.FileInvoice,
          permissions: [
            arrayPath([Modules.Ddd, Permissions.Dossier, Permissions.Show])
          ],
        },
        {
          title: 'ddd.menu.dossiers.title',
          routerUrl: UriUtils.submenu(Entities.mboDossiers, Modules.Ddd),
          icon: FontAwesomeIcon.FileInvoice,
          permissions: [
            arrayPath([Modules.Ddd, MboDossierPermissions.MboDossier, Permissions.Show])
          ],
        },
        {
          title: 'ddd.menu.dossiers.title',
          routerUrl: `${Modules.Ddd}/${Entities.voDossiers}/${Entities.voPartB}`,
          icon: FontAwesomeIcon.FileInvoice,
          permissions: [
            arrayPath([Modules.Ddd, VoDossierPermissions.VoDossier, Permissions.Show])
          ],
        },
        {
          title: 'ddd.menu.reports.title',
          routerUrl: UriUtils.submenu(Entities.reports, Modules.Ddd),
          icon: FontAwesomeIcon.ChartLine,
          permissions: [arrayPath([Modules.Ddd, Permissions.Report, Permissions.Show])],
        },
        {
          title: 'ddd.menu.special-transfers.title',
          routerUrl: UriUtils.submenu(Entities.specialTransfers, Modules.Ddd),
          icon: FontAwesomeIcon.ExchangeAlt,
          permissions: [
            arrayPath([Modules.Ddd, SpecialTransfersPermissions.MboSpecialTransfer, Permissions.Show]),
            arrayPath([Modules.Ddd, SpecialTransfersPermissions.VoSpecialTransfer, Permissions.Show]),
          ],
        },
        {
          title: 'ddd.menu.trainings.title',
          routerUrl: UriUtils.submenu(Entities.training, Modules.Ddd),
          icon: FontAwesomeIcon.MapSigns,
          permissions: [
            arrayPath([Modules.Ddd, TrainingsPermissions.Training, Permissions.Show]),
          ],
        },
        {
          title: 'ddd.menu.settings.title',
          routerUrl: UriUtils.submenu(Entities.setting, Modules.Ddd),
          icon: FontAwesomeIcon.Cogs,
          permissions: [
            arrayPath([Modules.Ddd, Permissions.MboSettings, Permissions.Show]),
          ],
        },
        {
          title: 'ddd.menu.settings.title',
          routerUrl: UriUtils.submenu(Entities.subMenuSettings, Modules.Ddd),
          icon: FontAwesomeIcon.Cogs,
          permissions: [
            arrayPath([Modules.Ddd, Permissions.Settings, Permissions.Show]),
          ],
        },
        {
          title: 'ddd.menu.pipeline.title',
          routerUrl: UriUtils.submenu(Entities.pipeline, Modules.Ddd),
          icon: FontAwesomeIcon.CodeBrach,
          permissions: [
            arrayPath([Modules.Ddd, Permissions.Pipeline, Permissions.Show])
          ],
        },
        {
          title: 'ddd.menu.reports.title',
          routerUrl: UriUtils.submenu(Entities.voReports, Modules.Ddd),
          icon: FontAwesomeIcon.ChartLine,
          permissions: [arrayPath([Modules.Ddd, VoReportPermissions.VoReport, Permissions.Show])],
        },
        {
          title: 'ddd.menu.setup.title',
          routerUrl: UriUtils.submenu(Entities.setup, Modules.Ddd),
          icon: FontAwesomeIcon.Cog,
          permissions: [arrayPath([Modules.Ddd, SetupPermissions.Setup, Permissions.Show])],
        },
        {
          title: 'ddd.menu.opp.title',
          routerUrl: UriUtils.submenu(Entities.opps, Modules.Ddd),
          icon: FontAwesomeIcon.LifeRing,
          permissions: [arrayPath([Modules.Ddd, VoOppPermissions.VoOpp, Permissions.Show])],
        },
        {
          title: 'ddd.menu.student.title',
          routerUrl: UriUtils.submenu(Entities.myStudents, Modules.Ddd),
          icon: FontAwesomeIcon.Child,
          permissions: [arrayPath([Modules.Ddd, Permissions.Student, Permissions.Show])],
        },
        {
          title: 'ddd.menu.mbo-institutions.title',
          routerUrl: UriUtils.submenu(Entities.institutions, Modules.Ddd),
          icon: FontAwesomeIcon.Bars,
          permissions: [arrayPath([Modules.Ddd, MboInstitutionPermissions.MboInstitution, Permissions.Show])],
        },
        {
          title: 'ddd.users.part-b.title',
          routerUrl: UriUtils.submenu(Entities.Users, Modules.Ddd),
          icon: FontAwesomeIcon.Users,
          permissions: [arrayPath([Modules.Ddd, VoUsersPermissions.Users, Permissions.Show])],
        },
        {
          title: 'ddd.menu.opp.title',
          routerUrl: UriUtils.submenu(Entities.opp, Modules.Ddd),
          icon: FontAwesomeIcon.LifeRing,
          permissions: [arrayPath([Modules.Ddd, VoOppPermissions.Opp, Permissions.Show])],
        },
        {
          title: 'ddd.menu.special-transfers.title',
          routerUrl: UriUtils.submenu(Entities.specialTransferManage, Modules.Ddd),
          icon: FontAwesomeIcon.Fire,
          permissions: [arrayPath([Modules.Ddd, SpecialTransfersPermissions.SpecialTransfer, Permissions.Show])],
        },
        {
          title: 'ddd.menu.form-setting.title',
          routerUrl: UriUtils.submenu(Entities.formSettings, Modules.Ddd),
          icon: FontAwesomeIcon.SlidersH,
          permissions: [arrayPath([Modules.Ddd, FormSettingsPermissions.FormSetting, Permissions.Show])],
        },
        {
          title: 'ddd.menu.transfers.title',
          routerUrl: UriUtils.submenu(Entities.transfers, Modules.Ddd),
          icon: FontAwesomeIcon.ExchangeAlt,
          permissions: [arrayPath([Modules.Ddd, TransferPermissions.Transfer, Permissions.Show])],
        },
        {
          title: 'ddd.menu.connection.title',
          routerUrl: UriUtils.submenu(Entities.connections, Modules.Ddd),
          icon: FontAwesomeIcon.ShareAlt,
          permissions: [arrayPath([Modules.Ddd, ConnectionPermissions.Connection, Permissions.Show])],
        },
        {
          title: 'ddd.menu.tool.title',
          routerUrl: UriUtils.submenu(Entities.tools, Modules.Ddd),
          icon: FontAwesomeIcon.Tools,
          permissions: [arrayPath([Modules.Ddd, ToolsPermissions.Tool, Permissions.Show])],
        },
      ],
    },
    {
      title: 'intergrip.menu.manage',
      activeSubRoute: '/manage',
      routerUrl: UriUtils.list(Entities.Students),
      exact: false,
      icon: FontAwesomeIcon.Cog,
      key: 'manage',
      permissions: [],
      children: [
        {
          title: 'intergrip.menu.students',
          routerUrl: UriUtils.submenu(Entities.Students),
          icon: FontAwesomeIcon.Users,
          permissions: [
              arrayPath([Modules.IntergripModule, Permissions.Student, Permissions.Show]),
          ],
          permissionsExcept: [
              arrayPath([Modules.TransferVo, Permissions.TransferCoach, UserPermissions.User, Permissions.Show]),
          ],
          dataTest: 'menu-item-students',
        },
        {
          title: 'intergrip.menu.users',
          routerUrl: UriUtils.submenu(Entities.Users),
          icon: FontAwesomeIcon.User,
          permissions: [
              arrayPath([Modules.IntergripModule, UserPermissions.User, Permissions.Show]),
              arrayPath([Modules.IntergripModule, UserPermissions.User, Permissions.Manage]),
              arrayPath([UserPermissions.User, Permissions.Show]),
              arrayPath([UserPermissions.User, Permissions.Manage]),
              arrayPath([Modules.TransferVo, Permissions.TransferCoach, UserPermissions.User, Permissions.Show]),
          ],
          dataTest: 'menu-item-users',
        },
        {
          title: 'kov-po.student.overview.title',
          routerUrl: UriUtils.submenu(Entities.Children),
          icon: FontAwesomeIcon.Users,
          permissions: [
              arrayPath([Modules.KovPo, KovPoPermissions.Child, Permissions.Show]),
              arrayPath([Modules.KovPo, KovPoPermissions.ChildList, Permissions.Show]),
              arrayPath([Modules.KovPo, Permissions.SchoolClass, Permissions.Show]),
              arrayPath([Modules.KovPo, KovPoPermissions.Student, Permissions.Label]),
          ],
          permissionsExcept: [
              arrayPath([Modules.KovPo, KovPoPermissions.Daycare, Permissions.Label]),
              arrayPath([Modules.KovPo, KovPoPermissions.Format, Permissions.Show]),
          ],
          dataTest: 'menu-item-students',
        },
        {
          title: 'intergrip.child.overview.title',
          routerUrl: UriUtils.submenu(Entities.Children),
          icon: FontAwesomeIcon.Users,
          permissions: [
              arrayPath([Modules.KovPo, KovPoPermissions.Child, Permissions.Show]),
              arrayPath([Modules.KovPo, KovPoPermissions.ChildList, Permissions.Show]),
              arrayPath([Modules.KovPo, Permissions.SchoolClass, Permissions.Show]),
          ],
          permissionsExcept: [
              arrayPath([Modules.KovPo, KovPoPermissions.Student, Permissions.Label]),
          ],
          dataTest: 'menu-item-students',
        },
        {
          title: 'intergrip.menu.instances',
          routerUrl: UriUtils.submenu(Entities.Instances),
          icon: FontAwesomeIcon.Building,
          permissions: [
            arrayPath([Modules.IntergripModule, Permissions.School, Permissions.Show]),
            arrayPath([Modules.IntergripModule, RegionsPermissions.Region, Permissions.Show]),
            arrayPath([Modules.IntergripModule, InstancesPermissions.MboInstitution, Permissions.Show]),
            arrayPath([Modules.IntergripModule, InstancesPermissions.VoInstitution, Permissions.Show]),
            arrayPath([Modules.IntergripModule, InstancesPermissions.VoSchoolGroup, Permissions.Show]),
          ],
          dataTest: 'menu-item-instances',
        },
        {
          title: 'intergrip.menu.communication',
          routerUrl: UriUtils.submenu(Entities.Communications),
          icon: FontAwesomeIcon.Envelope,
          permissions: [
            arrayPath([Modules.IntergripModule, CommunicationPerimssions.Communication, Permissions.Show])
          ],
          dataTest: 'menu-item-communication',
        },
        {
          title: 'transfer-vo.menu.mbo-checks',
          routerUrl: UriUtils.submenu(Entities.MboCheck),
          icon: FontAwesomeIcon.Tasks,
          permissions: [
            arrayPath([Modules.TransferVo, MboCheckPermissions.MboCheck, Permissions.Show]),
            arrayPath([Modules.TransferMbo, MboCheckPermissions.MboCheck, Permissions.Show]),
            arrayPath([Modules.TransferMbo, MboCheckPermissions.MboCheckFetchService, Permissions.Show]),
            arrayPath([Modules.TransferMbo, MboCheckPermissions.MboCheckFetchService, Permissions.Manage]),
          ],
          dataTest: 'menu-item-mbo-checks',
        },
        {
          title: 'intergrip.menu.trainings',
          routerUrl: UriUtils.submenu(Entities.Trainings),
          icon: FontAwesomeIcon.ChalkBoardTeacher,
          permissions: [
            arrayPath([Modules.IntergripModule, TrainingsPermissions.RioConnections, Permissions.Show]),
            arrayPath([Modules.IntergripModule, TrainingsPermissions.Sector, Permissions.Show]),
            arrayPath([Modules.IntergripModule, TrainingsPermissions.Sector, Permissions.Manage]),
          ],
          dataTest: 'menu-item-trainings',
        },
        {
          title: 'intergrip.menu.monitoring',
          routerUrl: UriUtils.submenu(Entities.intergrip1Monitoring),
          icon: FontAwesomeIcon.SyncAlt,
          permissions: [
            arrayPath([Modules.IntergripModule, Integrip1Permissions.Monitoring, Permissions.Show])
          ],
          dataTest: 'menu-item-monitoring',
        },
        {
          title: 'intergrip.menu.settings',
          routerUrl: UriUtils.submenu(Entities.intergrip1Manage),
          icon: FontAwesomeIcon.Cog,
          permissions: [arrayPath([Modules.IntergripModule, Integrip1Permissions.Manage, Permissions.Show])],
          dataTest: 'menu-item-manage',
        },
        {
          title: 'intergrip.menu.sales-list-requests',
          routerUrl: UriUtils.list(Entities.Sales),
          icon: FontAwesomeIcon.EnvelopeOpenText,
          permissions:
            [
              arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.Show]),
              arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.List]),
            ],
        },
        {
          title: 'intergrip.menu.other-management',
          routerUrl: UriUtils.submenu(Entities.OtherSettings),
          icon: FontAwesomeIcon.EllipsisV,
          permissions: [
            arrayPath([Modules.IntergripModule, OtherSettingsPermissions.Translation, Permissions.Manage, Permissions.Tenant]),
            arrayPath([Modules.IntergripModule, OtherSettingsPermissions.Setting, Permissions.Manage, Permissions.Tenant]),
            arrayPath([Modules.IntergripModule, Permissions.BaseDataValue, Permissions.Manage, Permissions.Tenant]),
          ],
          dataTest: 'menu-item-other-management',
        },
        {
          title: 'intergrip.menu.connections',
          routerUrl: UriUtils.submenu(Entities.ConnectionsManagement),
          icon: FontAwesomeIcon.ShareAlt,
          permissions: [
            arrayPath([Modules.TransferMbo, PronexusPermissions.PronexusService, Permissions.Show]),
            arrayPath([Modules.TransferMbo, PronexusPermissions.PronexusService, Permissions.Manage]),
            arrayPath([Modules.TransferMbo, PronexusPermissions.PronexusIncomingService, Permissions.Show]),
            arrayPath([Modules.TransferVo, PronexusPermissions.PronexusIncomingService, Permissions.Show]),
            arrayPath([Modules.TransferMbo, PronexusPermissions.PronexusOutgoingService, Permissions.Show]),
            arrayPath([Modules.TransferVo, PronexusPermissions.PronexusOutgoingService, Permissions.Show])
          ],
          dataTest: 'menu-item-connection',
        },

      ],
    },
    {
      title: 'intergrip.menu.monitorus',
      activeSubRoute: '/monitorus',
      routerUrl: UriUtils.list(Entities.monitorusList),
      exact: false,
      key: 'monitorus',
      icon: FontAwesomeIcon.Dossier,
      permissions: [],
      children: [
        {
          title: 'monitorus.menu.uploads.title',
          routerUrl: UriUtils.submenu(Entities.monitorusImport, Modules.Monitorus),
          icon: FontAwesomeIcon.HandPointRight,
          permissions: [
            arrayPath([Modules.Monitorus, ImportPermissions.Import, Permissions.Show]),
            arrayPath([Modules.Monitorus, ImportPermissions.ImportFiles, Permissions.Show]),
          ],
        },
        {
          title: 'monitorus.menu.info.title',
          routerUrl: UriUtils.submenu(Entities.Info, Modules.Monitorus),
          icon: FontAwesomeIcon.InfoCircle,
          permissions: [
            arrayPath([Modules.Monitorus, Permissions.Info, Permissions.Show])
          ],
        },
        {
          title: 'monitorus.menu.reports.title',
          routerUrl: UriUtils.submenu(Entities.monitorusReportsSubmenu, Modules.Monitorus),
          icon: FontAwesomeIcon.ChartBar,
          permissions: [
            arrayPath([Modules.Monitorus, MonitorusReportsPermissions.Reports, Permissions.Show]),
            arrayPath([Modules.Monitorus, MonitorusReportsPermissions.MboReports, Permissions.Show]),
            arrayPath([Modules.Monitorus, MonitorusReportsPermissions.VoReports, Permissions.Show]),
          ],
        },
        {
          title: 'monitorus.menu.settings.title',
          routerUrl: UriUtils.submenu(Entities.Settings, Modules.Monitorus),
          icon: FontAwesomeIcon.Check,
          permissions: [
            arrayPath([Modules.Monitorus, Permissions.Settings, Permissions.Show])
          ],
        },
        {
          title: 'monitorus.menu.list.title',
          routerUrl: UriUtils.submenu(Entities.List, Modules.Monitorus),
          icon: FontAwesomeIcon.Bars,
          permissions: [
            arrayPath([Modules.Monitorus, Permissions.List, Permissions.Show]),
          ],
        },
      ],
    },
    {
      title: 'intergrip.menu.zoekjouwstudie',
      activeSubRoute: '/zoekjouwstudie',
      routerUrl: UriUtils.list(Entities.lobVoorlichtingenManagement),
      exact: false,
      key: 'zoekjouwstudie',
      icon: FontAwesomeIcon.Dossier,
      permissions: [],
      children: [
        {
          title: 'zoekjouwstudie.menu.zoekjouwstudie-management.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenManagement, Modules.LobVoorlichtingen),
          icon: FontAwesomeIcon.FolderOpen,
          permissions: ['zoekjouwstudie.management.show', 'zoekjouwstudie.management-admin.show'],
        },
        {
          title: 'zoekjouwstudie.menu.zoekjouwstudie-settings.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenSettings, Modules.LobVoorlichtingen),
          icon: FontAwesomeIcon.Cog,
          permissions: [
            arrayPath([Modules.LobVoorlichtingen, Permissions.Settings, Permissions.Show]),
          ],
        },
        {
          title: 'zoekjouwstudie.menu.zoekjouwstudie-offer.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenOffer, Modules.LobVoorlichtingen),
          icon: FontAwesomeIcon.Server,
          permissions: ['zoekjouwstudie.offer.show'],
        },
        {
          title: 'zoekjouwstudie.menu.zoekjouwstudie-phases.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenPhases, Modules.LobVoorlichtingen),
          icon: FontAwesomeIcon.SlidersH,
          permissions: [
            arrayPath([Modules.LobVoorlichtingen, PhasesPermissions.Phases, Permissions.Show]),
          ],
        },
        {
          title: 'zoekjouwstudie.menu.my-students.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenMyStudents, Modules.LobVoorlichtingen),
          icon: FontAwesomeIcon.Users,
          permissions: [
            arrayPath([Modules.LobVoorlichtingen, Permissions.MyStudents, Permissions.Show]),
          ]
        },
        {
          title: 'zoekjouwstudie.menu.activities.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenActivities, Modules.LobVoorlichtingen),
          icon: FontAwesomeIcon.Microphone,
          permissions: [
            arrayPath([Modules.LobVoorlichtingen, LobActivityActivitiesPerimissions.Activities, Permissions.Show]),
          ],
        },
        {
          title: 'zoekjouwstudie.menu.search.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenEducation, Modules.LobVoorlichtingen),
          icon: FontAwesomeIcon.Search,
          permissions: [
            arrayPath([Modules.LobVoorlichtingen, Permissions.Search, Permissions.Show]),
          ],
        },
        {
          title: 'zoekjouwstudie.menu.registrations.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenRegistrations, Modules.LobVoorlichtingen),
          icon: FontAwesomeIcon.Calendar,
          permissions: [
            arrayPath([Modules.LobVoorlichtingen, LobActivityRegistrationPerimissions.Registrations, Permissions.Show]),
          ],
        },
        {
          title: 'zoekjouwstudie.menu.sort.title',
          routerUrl: UriUtils.submenu(Entities.lobVoorlichtingenSort, Modules.LobVoorlichtingen),
          icon: FontAwesomeIcon.ExchangeAlt,
          permissions: [
            arrayPath([Modules.LobVoorlichtingen, Permissions.Sort, Permissions.Show]),
          ],
        },
      ],
    },
    {
      title: 'intergrip.menu.inflow',
      activeSubRoute: `/${Entities.Instroom}`,
      routerUrl: UriUtils.list(Entities.Instroom),
      exact: false,
      key: Entities.Instroom,
      icon: FontAwesomeIcon.Dossier,
      permissions: [],
      children: [
        {
          title: 'inflow.menu.signups.title',
          routerUrl: UriUtils.submenu(Entities.Signups, Modules.Inflow),
          icon: FontAwesomeIcon.FileSignature,
          permissions: [arrayPath([Modules.Inflow, Permissions.ChoiceStudentYear, Permissions.Show])],
          dataTest: 'menu-item-signups',
        },
      ],
    },
    {
      title: 'kov-po.module-name',
      activeSubRoute: `/${Modules.KovPo}`,
      routerUrl: UriUtils.list(Modules.KovPo),
      exact: false,
      key: Entities.KovPo,
      icon: FontAwesomeIcon.Dossier,
      permissions: [arrayPath([Modules.KovPo, KovPoPermissions.Format, Permissions.Show])],
      children: [
        {
            title: 'kov-po.observation.label',
            routerUrl: UriUtils.submenu(`${Entities.ObservationFormats}/${Entities.Overview}`, Modules.KovPo),
            icon: FontAwesomeIcon.FileAlt,
            permissions: [arrayPath([Modules.KovPo, KovPoPermissions.Format, Permissions.Show])],
            dataTest: 'menu-item-youth-care',
        },
        {
            title: 'kov-po.my-observations.title',
            routerUrl: UriUtils.submenu(`${Entities.Observations}`, Modules.KovPo),
            icon: FontAwesomeIcon.FileAlt,
            permissions: [arrayPath([Modules.KovPo, KovPoPermissions.Observation, Permissions.Show])],
            dataTest: 'menu-item-youth-care-observations',
        },
        {
            title: 'kov-po.new-children.title',
            routerUrl: UriUtils.submenu(`${Entities.NewChildren}/${Entities.Overview}`, Modules.KovPo),
            icon: FontAwesomeIcon.Users,
            permissions: [arrayPath([Modules.KovPo, KovPoPermissions.TransferRequest, Permissions.Show])],
            dataTest: 'menu-item-youth-care-new-children',
        },
        {
            title: 'kov-po.special-transfer.title',
            routerUrl: UriUtils.submenu(`${Entities.SpecialTransfer}`, Modules.KovPo),
            icon: FontAwesomeIcon.ExchangeAlt,
            permissions: [arrayPath([Modules.KovPo, KovPoPermissions.TransferRequest, KovPoPermissions.SpecialTransfer, Permissions.Show])],
            dataTest: 'menu-item-special-transfer',
        },
      ],
    },
    {
      title: 'dashboard-ocw.module-name',
      activeSubRoute: `/${Modules.DashboardOCW}`,
      routerUrl: UriUtils.list(Modules.DashboardOCW),
      exact: false,
      key: Entities.DashboardOCW,
      icon: FontAwesomeIcon.Dossier,
      permissions: [
          arrayPath([Modules.DashboardOCW, DashboardOCWPermissions.MboVsvDashboard, Permissions.Show])
      ],
      children: [
        {
            title: 'dashboard-ocw.report-pages.mbo-dropouts.label',
            routerUrl: UriUtils.submenu(Entities.MboDropouts, Modules.DashboardOCW),
            icon: FontAwesomeIcon.ChartLine,
            permissions: [
                arrayPath([Modules.DashboardOCW, DashboardOCWPermissions.MboVsvDashboard, Permissions.Show]),
            ],
            dataTest: '/dashboard-ocw/mbo-dropouts',
        }
      ]
    },
    {
      title: 'youth-care-monitor.module-name',
      activeSubRoute: `/${Modules.YouthCareMonitor}`,
      routerUrl: UriUtils.list(Modules.YouthCareMonitor),
      exact: false,
      key: Modules.YouthCareMonitor,
      icon: FontAwesomeIcon.Dossier,
      permissions: [arrayPath([Modules.YouthCareMonitor, Permissions.KeyFigures, Permissions.Show])],
      children: [
        {
          title: 'youth-care-monitor.key-figures',
          routerUrl: UriUtils.submenu(Entities.KeyFigures, Modules.YouthCareMonitor),
          icon: FontAwesomeIcon.ChartPie,
          permissions: [arrayPath([Modules.YouthCareMonitor, Permissions.KeyFigures, Permissions.Show])],
          dataTest: 'menu-item-youth-care',
        },
        {
          title: 'youth-care-monitor.budget',
          routerUrl: UriUtils.submenu(Entities.Budget, Modules.YouthCareMonitor),
          icon: FontAwesomeIcon.Coins,
          permissions: [arrayPath([Modules.YouthCareMonitor, Permissions.Budget, Permissions.Show])],
          dataTest: 'menu-item-youth-care',
        },
        {
          title: 'youth-care-monitor.prognosis',
          routerUrl: UriUtils.submenu(Entities.Prognosis, Modules.YouthCareMonitor),
          icon: FontAwesomeIcon.ChartLine,
          permissions: [arrayPath([Modules.YouthCareMonitor, Permissions.Prognosis, Permissions.Show])],
          dataTest: 'menu-item-youth-care',
        },
        {
          title: 'youth-care-monitor.providers',
          routerUrl: UriUtils.submenu(Entities.Providers, Modules.YouthCareMonitor),
          icon: FontAwesomeIcon.Users,
          permissions: [arrayPath([Modules.YouthCareMonitor, Permissions.Providers, Permissions.Show])],
          dataTest: 'menu-item-youth-care',
        },
        {
          title: 'youth-care-monitor.referrer',
          routerUrl: UriUtils.submenu(Entities.Referrers, Modules.YouthCareMonitor),
          icon: FontAwesomeIcon.Compass,
          permissions: [arrayPath([Modules.YouthCareMonitor, Permissions.Referrer, Permissions.Show])],
          dataTest: 'menu-item-youth-care',
        },
        {
          title: 'youth-care-monitor.comparison',
          routerUrl: UriUtils.submenu(Entities.Comparison, Modules.YouthCareMonitor),
          icon: FontAwesomeIcon.ScaleUnbalanced,
          permissions: [arrayPath([Modules.YouthCareMonitor, Permissions.Comparison, Permissions.Show])],
          dataTest: 'menu-item-youth-care',
        },
      ],
    },
    {
      title: 'dashboard-rmc.module-name',
      routerUrl: Entities.DashboardRMC,
      icon: ModuleIconMap[Entities.DashboardRMC],
      key: Entities.DashboardRMC,
      activeSubRoute: `/${Entities.DashboardRMC}`,
      permissions: [],
      children: [
        /* COMMENTED FOR FUTURE
        {
          title: 'dashboard-rmc.dashboard-reports.menu.label',
          routerUrl: UriUtils.submenu(Entities.DashboardSubMenu, Modules.DashboardRMC),
          icon: FontAwesomeIcon.FileSignature,
          permissions:
            [
            /!*  arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, DashboardRmcPermissions.Supervisor, Permissions.Manage])*!/
            ],
          dataTest: '',
        },*/
        {
          title: 'dashboard-rmc.dashboard-reports.vsv-factsheet.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcVsvFactsheet}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/vsv-factsheet',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.vsv-progress.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcVsvProgress}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/vsv-progress',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.vsv-education.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcVsvEducation}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/vsv-education',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.vsv-work.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcVsvWork}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/vsv-work',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.vsv-other.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcVsvOther}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/vsv-other',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.vsv-targets.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcVsvTargets}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/vsv-targets',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.vsv-regional-worklists.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcVsvRegionalWorklists}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/vsv-regional-worklists',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.export-regional-vsv-worklists.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcExportVsvRegionalWorklists}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Manage]),
            ],
          dataTest: '/dashboard-rmc/reports/export-vsv-regional-worklists',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.jikp-overview.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcJikpOverview}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/jikp-overview',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.jikp-education.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcJikpEducation}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/jikp-education',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.jikp-work.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcJikpWork}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/jikp-work',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.jikp-daycare.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcJikpDaycare}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/jikp-daycare',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.jikp-other.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcJikpOther}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/jikp-other',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.jikp-regional-worklists.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcJikpRegionalWorklists}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/jikp-regional-worklists',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.export-regional-jikp-worklists.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcExportJikpRegionalWorklists}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Manage]),
            ],
          dataTest: '/dashboard-rmc/reports/export-jikp-regional-worklists',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.export-vsv.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcExportVsv}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Manage]),
            ],
          dataTest: '/dashboard-rmc/reports/export-vsv-file',
        },
        {
          title: 'dashboard-rmc.dashboard-reports.no-c-delivery.label',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardRmcNoCDelivery}`, Modules.DashboardRMC),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Show]),
            ],
          dataTest: '/dashboard-rmc/reports/no-c-delivery',
        },
        {
          title: 'dashboard-rmc.uploaded-files.menu.label',
          routerUrl: UriUtils.submenu(Entities.UploadedFiles, Modules.DashboardRMC),
          icon: FontAwesomeIcon.FileSignature,
          permissions:
            [
              arrayPath([Modules.DashboardRMC, Entities.DashboardRMC, Permissions.Manage]),
            ],
          dataTest: '',
        },
      ]
    },
    {
      title: 'dashboard-us.module-name',
      routerUrl: Entities.DashboardUS,
      icon: ModuleIconMap[Entities.DashboardUS],
      key: Entities.DashboardUS,
      activeSubRoute: `/${Entities.DashboardUS}`,
      permissions: [],
      children: [
        {
          title: 'dashboard-us.report.student-level',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSStudentLevel}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.vo, Permissions.Show]),
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Show]),
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
            ],
          dataTest: '/dashboard-us/reports/student-level',
        },
        {
          title: 'dashboard-us.report.school-level',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSSchoolLevel}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.vo, Permissions.Show]),
            ],
          dataTest: '/dashboard-us/reports/school-level',
        },
        {
          title: 'dashboard-us.report.school-level',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSSchoolLevelRegional}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
            ],
          dataTest: '/dashboard-us/reports/school-level-regional',
        },
        {
          title: 'dashboard-us.report.mbo',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSMBO}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Show]),
            ],
          dataTest: '/dashboard-us/reports/mbo',
        },
        {
          title: 'dashboard-us.report.mbo',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSMBORegional}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
            ],
          dataTest: '/dashboard-us/reports/mbo-regional',
        },
        {
          title: 'dashboard-us.report.graduated',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSGraduated}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
            ],
          dataTest: '/dashboard-us/reports/graduated',
        },
        {
          title: 'dashboard-us.report.graduated',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSGraduatedRegional}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
            ],
          dataTest: '/dashboard-us/reports/graduated-regional',
        },
        {
          title: 'dashboard-us.report.vo-report',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSVoReport}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
            ],
          dataTest: '/dashboard-us/reports/vo-report',
        },
        {
          title: 'dashboard-us.report.mbo-report',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSMboReport}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Lp, Permissions.Show]),
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
            ],
          dataTest: '/dashboard-us/reports/mbo-report',
        },
        {
          title: 'dashboard-us.report.mbo-download-report',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSMboDownloadReport}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.ChartLine,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Manage]),
            ],
          dataTest: '/dashboard-us/reports/mbo-download-report',
        },
        {
          title: 'dashboard-us.report.total-overview.report',
          routerUrl: UriUtils.submenu(`${Entities.DashboardReports}/${Entities.DashboardUSTotalDataOverview}`, Modules.DashboardUS),
          icon: FontAwesomeIcon.FileAlt,
          permissions:
              [
                  arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Show]),
                  arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.admin, Permissions.Show]),
                  arrayPath([Modules.DashboardUS, Entities.DashboardUS, Entities.Mbo, Permissions.Manage]),
              ],
          dataTest: '/dashboard-us/reports/total-data-overview',
        },
        {
          title: 'dashboard-us.uploaded-files.menu.label',
          routerUrl: UriUtils.submenu(Entities.UploadedFiles, Modules.DashboardUS),
          icon: FontAwesomeIcon.FileSignature,
          permissions:
            [
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Permissions.Show]),
              arrayPath([Modules.DashboardUS, Entities.DashboardUS, Permissions.Manage]),
            ],
          dataTest: '/dashboard-us/uploaded-files',
        },
      ]
    },
   {
      title: 'intergrip.menu.my-products',
      routerUrl: Entities.My_Products,
      icon: ModuleIconMap[Entities.My_Products],
      key: Entities.My_Products,
      activeSubRoute: `/${Entities.My_Products}`,
      permissions:
         [
          arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.Manage]),
          arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.Show]),
          arrayPath([Modules.IntergripModule, SalesRequestsPermissions.Sales, Permissions.List]),
        ],
    },
    {
      title: 'intergrip.menu.student-portal',
      activeSubRoute: '/student-portal',
      routerUrl: Modules.StudentPortal,
      key: 'student-portal',
      icon: FontAwesomeIcon.GraduationCap,
      permissions: [],
      children: [
        {
          title: 'student-portal.menu.system-preferences.title',
          routerUrl: UriUtils.submenu(`${Entities.SystemPreference}`, Modules.StudentPortal),
          icon: FontAwesomeIcon.Cogs,
          permissions: [

          ],
        },
        {
          title: 'student-portal.menu.settings.title',
          routerUrl: UriUtils.submenu(Entities.setting, Modules.StudentPortal),
          icon: FontAwesomeIcon.Cog,
          permissions: [

          ],
        },
      ],
    },
  ];
  public activeMenuItem$: Observable<NavMenuItem[]>;
  public activeMenuItem = new BehaviorSubject<NavMenuItem>(null);

  constructor(private translateService: TranslateService,  private permissionService: NgxPermissionsService, private appService: AppService) {
  }

  public setActiveMenuitem(navMenuItem: NavMenuItem): void {
    this.appService.isLoading.next(false);
    this.activeMenuItem.next(navMenuItem);
  }

  public getActiveMenuItem(): Observable<NavMenuItem> {
    return this.activeMenuItem.asObservable();
  }
}
